const createReviewsSwiper = () => {
    const swiper = new Swiper("#reviews-swiper", {
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        speed: 600,
        // autoplay: {
        //     delay: 5000,
        // },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {

            991: {
                slidesPerView: 2,
                spaceBetween: 60,
            },
            1200: {
                slidesPerView: 2.2,
                spaceBetween: 60,
            }
        }

    });
}

export default createReviewsSwiper;