const createCatalogSwiper = () => {

    let catalogSwiper;
    const catalog = document.querySelector('#catalog');
    const catalogSwiperWrapper = document.querySelector('#catalog-swiper-wrapper');
    const catalogClose = document.querySelector('[data-catalog-close]');
    const portfolioWrapper = document.querySelector('#portfolio__wrapper');
    const portfolioLinks = document.querySelectorAll('.portfolio__item');

    let modalState = {
        kitchen: [],
        closet: [],
        lounge: [],
        bedroom: [],
        nursery: [],
        bathroom: []
    };

    portfolioLinks.forEach(item => {
        const directory = item.dataset.portfolio;
        const count = item.dataset.count;

        for (let i = 1; i <= count; i++) {
            preloadImage(directory, i);
        }
        item.addEventListener('click', (e) => {
            e.preventDefault();
            portfolioWrapper.classList.add('hidden');
            catalog.classList.add('active');
            createSlider(directory, initCatalogSwiper);
        })
    })

    function preloadImage(directory, number) {
        const img = document.createElement('img')
        img.src = `img/slider/${directory}/${number}.jpg`;
        img.alt = `${directory}${number}_img`;
        // img.classList.add('hidden');

        if (modalState[directory]) {
            modalState[directory].push(img)
        }
    }

    function createSlider(directory, callback) {
        createSlides(directory, callback)
    }

    function createSlides(directory, callback) {
        modalState[directory].forEach(item => {
            const div = document.createElement("div");
            div.classList.add('swiper-slide');

            div.appendChild(item);
            catalogSwiperWrapper.appendChild(div);
        })
        callback();
    }

    function initCatalogSwiper() {
        catalogSwiper = new Swiper("#catalog-swiper", {
            observer: true,
            observeSlideChildren: true,
            observeParents: true,
            slidesPerView: 1.3,
            spaceBetween: 10,
            speed: 600,
            autoplay: {
                delay: 5000,
            },
            centeredSlides: true,
            loop: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 1.5
                },
                991: {

                },
                1200: {
                    slidesPerView: 2.5,
                    spaceBetween: 20,

                }
            }

        });

        catalogSwiper.on('activeIndexChange', function () {
            const currentSliderItems = document.querySelectorAll(`#catalog-swiper .swiper-slide`);
            removeAnimationOnSlides(currentSliderItems);
        });

        activateTab();
    }

    const removeAnimationOnSlides = function removeAnimationOnSlides(currentSliderItems) {
        currentSliderItems.forEach(item => item.classList.remove('animation-active'));
    };



    function destroyCatalogSwiper() {
        catalogSwiper.destroy();
        const slides = document.querySelectorAll('#catalog-swiper-wrapper .swiper-slide');
        slides.forEach(item => item.remove())

    }

    catalogSwiperWrapper.addEventListener('click', (event) => {
        if (event.target && event.target.closest('.swiper-slide-prev')) {

            swiperChangeIndex(catalogSwiper, '.swiper-slide-prev')
        }
        if (event.target && event.target.closest('.swiper-slide-next')) {

            swiperChangeIndex(catalogSwiper, '.swiper-slide-next')
        }
    });

    function swiperChangeIndex(slider, otherSlide) {

        const slide = catalogSwiperWrapper.querySelector(otherSlide);
        const index = slide.dataset.swiperSlideIndex;

        slider.slideToLoop(index , 600, true)
    }

    catalogClose.addEventListener('click', () => {
        portfolioWrapper.classList.remove('hidden');
        catalog.classList.remove('active')
        destroyCatalogSwiper();
    })

    // Запускает появление каждого item внутри активного tabContent через интервал
    function activateTab() {
        const currentSliderItems = document.querySelectorAll(`#catalog-swiper .swiper-slide`);

        clearInterval(staggerInterval);
        removeAnimationOnSlides(currentSliderItems);

        item = 0;
        staggerInterval = setInterval(function () {
            currentSliderItems[item].classList.add('opacity','animation-active');
            item++;
            if (item >= currentSliderItems.length) clearInterval(staggerInterval);
        }, 200);
    }


    let staggerInterval,
        item = 0;
}

export default createCatalogSwiper;




