import {bodyUnfixPosition} from "./bodyControlPosition";

function clearForm() {
    const form = document.querySelector('#consultation-form');
    const formInputs = document.querySelectorAll('.feedForm__field-input');
    const formElements = document.querySelectorAll('[data-form-element]');
    form.reset();
    bodyUnfixPosition();
    formElements.forEach(item => {
        item.parentElement.classList.remove('feedForm__field-active');
        item.parentElement.classList.remove('invalidElem');
        item.classList.remove('invalidElem');

    })

    formInputs.forEach(item => {
        item.blur();
    })

}

export {clearForm};

