import {bodyFixPosition} from "./bodyControlPosition";
import {clearForm} from "./clearForm";

const initMicromodal = () => {

    try {
        MicroModal.init({
            // disableScroll: true,
            disableFocus: true,
            awaitCloseAnimation: true,// set to false, to remove close animation
            onShow: modal => bodyFixPosition(),
            onClose: modal => clearForm(),
            debugMode: true
        });
    } catch (e) {
        console.log("micromodal error: ", e);
    }



}

export default initMicromodal;