const submitForm = (form, wrapper, messages, messagesActiveClass, field, fieldActiveClass, modal) => {
    $(form).submit(function(e) {
        e.preventDefault();
        var ourForm = $(form);
        ourForm.valid();
        if (!$(this).valid()) {
            return;
        }
        $.ajax({
            type: "POST",
            url: "mailer/smart.php",
            data: $(this).serialize()
        }).done(function () {
            $(this).find("input , textarea").val("");
            $(field).removeClass(fieldActiveClass);
            ourForm.trigger('reset');
            $(wrapper).fadeOut('slow');
            setTimeout(() => {
                $(messages).addClass(messagesActiveClass)
                setTimeout(() => {
                    MicroModal.close(modal);
                    $(messages).removeClass(messagesActiveClass);
                    setTimeout(() => {
                        $(wrapper).attr("style", "display:block");
                    }, 250)
                }, 3000);
            }, 250);  // не трогать вложенность
        });
        return false;
    });

}

export default submitForm;