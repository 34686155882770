const controlInputs = (phoneId, inputs, inputsActiveClass) => {
    const input = document.querySelector(phoneId);

    const prefixNumber = (str) => {
        if (str === "7") {
            return "7 (";
        }
        if (str === "8") {
            return "7 (";
        }
        if (str === "9") {
            return "7 (9";
        }
        return "7 (";
    };

// ======================================
    input.addEventListener("input", (e) => {
        const value = input.value.replace(/\D+/g, "");
        const numberLength = 11;

        let result;
        if (input.value.includes("+8") || input.value[0] === "8") {
            result = "";
        } else {
            result = "+";
        }

        //
        for (let i = 0; i < value.length && i < numberLength; i++) {
            switch (i) {
                case 0:
                    result += prefixNumber(value[i]);
                    continue;
                case 4:
                    result += ") ";
                    break;
                case 7:
                    result += "-";
                    break;
                case 9:
                    result += "-";
                    break;
                default:
                    break;
            }
            result += value[i];
        }
        //
        input.value = result;
    });
// ======================================

    const feedInputList = document.querySelectorAll(inputs);

    feedInputList.forEach(input => {
        input.addEventListener('focus', () => {
            input.parentElement.classList.add(inputsActiveClass)
        })

        input.addEventListener('blur', () => {
            let data = input.value;

            if (input.type === 'checkbox') {
                console.log('da')
            }

            if (input.parentElement.classList.contains(inputsActiveClass)) {
                if (!data) {
                    input.parentElement.classList.remove(inputsActiveClass)
                }
            }
        })
    })
}

export default controlInputs;