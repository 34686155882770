const activateMenu = () => {
    // menu

    const menu = document.querySelector('.menu');
    const hamburger = document.querySelector('.hamburger');

    let Interval, Interval2;


    function menuToggle() {

        const menuToggleItems = document.querySelectorAll('[data-menu-toggle]');
        menuToggleItems.forEach(item => item.classList.toggle('active'));
        document.body.classList.toggle('modal-show');

        if (menu.classList.contains('active')) {
            document.body.style.paddingRight = getComputedStyle(document.querySelector('.scroll-fix')).width;
        } else {
            document.body.style.paddingRight = '';
        }
    }

    function removeAllActive() {
        let menuLinks = document.querySelectorAll('.menu__item.active'),
            menuSocialLinks = document.querySelectorAll('.menu__other.active');

        function removeActiveClass(...links) {
            links.forEach(item => {
                item.classList.remove('active')
            })
        }
        removeActiveClass(...menuLinks, ...menuSocialLinks);
    }



    hamburger.addEventListener('click', function () {


        const menuFirstList = document.querySelectorAll('.menu__item');
        const menuSecondList = document.querySelectorAll('.menu__other');

        let item = 0, item2 = 0;

        clearInterval(Interval);
        clearInterval(Interval2);
        removeAllActive();


        function triggerInterval(menuList, intervalObj, num) {

            if (num >= menuList.length) {
                clearInterval(intervalObj);
            } else if (!menu.classList.contains('active')) {
                clearInterval(intervalObj);
            }

            if (menuList[num]) {
                menuList[num].classList.add('active');
            }

        }

        Interval = setInterval(function () {
            triggerInterval(menuFirstList, Interval, item);
            item++;
        }, 200);

        Interval2 = setInterval(function () {
            triggerInterval(menuSecondList, Interval2, item2);
            item2++;
        }, 200);



    });

    const menuCloseList = document.querySelectorAll('[data-menu-trigger]');
    menuCloseList.forEach(item => item.addEventListener('click', menuToggle));
}

export default activateMenu;