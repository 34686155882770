import createCatalogSwiper from "./modules/createCatalogSwiper";
import createReviewsSwiper from "./modules/createReviewsSwiper";
import activateMenu from "./modules/activateMenu";
import activateNavigation from "./modules/activateNavigation";
import initHeader from "./modules/initHeader";
import initMicromodal from "./modules/initMicromodal";
import controlInputs from "./modules/controlInputs";
import validationForm from "./modules/validationForm";
import submitForm from "./modules/submitForm";
import './wow.min'


window.addEventListener('DOMContentLoaded', () => {
    "use strict";

    createCatalogSwiper()
    createReviewsSwiper();
    activateMenu();
    activateNavigation();
    initHeader();
    initMicromodal();
    controlInputs("#feedForm-phone", '.feedForm__field-input', 'feedForm__field-active');
    validationForm('#consultation-form');
    submitForm(
        '#consultation-form',
        '.feedForm__wrapper',
        '.feedForm__messages',
        'feedForm__messages-active',
        '.feedForm__field',
        'feedForm__field-active',
        'form1'
    );

    new WOW().init();
});