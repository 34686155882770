const activateNavigation = () => {
    // navigation

    const linksList = document.querySelectorAll('[data-menu-navigation]');

    linksList.forEach((item) => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            console.log(e.target);
            const id = e.target.getAttribute('href'),
                hrefItem = document.querySelector(`${id}`),
                blockValue = hrefItem.getBoundingClientRect().top + scrollY;

            function scrollTo() {
                window.scrollTo({
                    top: blockValue,
                    behavior: "smooth"
                });
            }
            if (200) {
                setTimeout(() => {
                    scrollTo();
                }, 200)
            } else {
                scrollTo();
            }
        })
    })

}

export default activateNavigation;